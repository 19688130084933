import { isLoggedIn } from "../models/auth";
import { createRouter, createWebHistory } from "vue-router";
import authRoutes from "./authRoutes";
import bookingRoutes from "./bookingRoutes";
import accountRoutes from "./accountRoutes";
import userRoutes from "./userRoutes";
import paymentRoutes from "./paymentRoutes";

const routes = [
	// {
	// 	path: "/home",
	// 	name: "AppHome",
	// 	component: () => import("../components/AppHome.vue"),
	// },
	...authRoutes,
	...bookingRoutes,
	...userRoutes,
	...accountRoutes,
	...paymentRoutes,
	{
		path: "/review/:bookingId",
		name: "MakeReview",
		props: true,
		component: () => import("../components/review/MakeReview.vue"),
	},
	{
		path: "/:pathMatch(.*)*",
		redirect: { name: "BookingList" },
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

router.beforeEach((to, from, next) => {
	if (!to.meta.noAuth)
		if (!isLoggedIn())
			return next({
				name: "Login",
				query: {
					redirect: to.path,
				},
			});
	return next();
});

export default router;
